import { Button, Typography, Box } from "@mui/material";
import React from "react";
import "../../../assets/styles/components/card2.css";
import PulseLoader from "react-spinners/PulseLoader";
import ReactShare from "../../../components/ReactShare";
import "../../../assets/styles/components/buttons.css";
import NonehoLogo from "../../../assets/images/NonehoBlueLogo.png";

export default ({
	content,
	onVote,
	voting,
	eventData,
	eventEnded,
	votedCandidate,
}) => {
	const links =
		content.details && content.details.socialMedia
			? Object.values(content.details)
			: [""];
	const voteBtnDisabled =
		eventEnded ||
		(eventData && !eventData.isActive) ||
		(voting && votedCandidate.candidateId !== content.candidateId);

	return (
		<div>
			<div className={"cardRoot"}>
				<div
					style={{
						height: content.height || "fit-content",
					}}
					id={"cardImg"}
				>
					{" "}
					<img
						src={
							content.photo ||
							`https://via.placeholder.com/500x450.png?text=${
								content.name || content.firstName
							}`
						}
						style={{ width: "100%" }}
					/>
				</div>

				<div className={"cart-elt-2"} style={{ position: "relative" }}>
					<div className="description-container">
						<div>
							<Typography variant="h6">
								{content.name || `${content.firstName} ${content.lastName}`}
							</Typography>
							{/* <Typography variant="subtitle1">Votes: {content.votes}</Typography> */}
							<br />
							{content.contestantCode ? (
								<Typography variant="subtitle1">
									Contestant Number: {content.contestantCode}
								</Typography>
							) : null}
							<br />
							<Typography variant="subtitle1">
								{" "}
								{content.description}
							</Typography>
							<br />
						</div>
						{/* <div className="contestant-qrCode">
              {content.candidateQrCodeUrl && <img src={content.candidateQrCodeUrl} alt="contestant bar code" style={{ width: "100%" }} />}
            </div> */}
					</div>
					{links[0] !== "" && (
						<div>
							<Typography variant="subtitle2">
								Contestant social medias
							</Typography>
							<br />
						</div>
					)}
					{!eventEnded && (
						<ReactShare
							link={`https://vote.zikomoawards.com/${eventData.id}/${
								content.id || content.candidateId
							}`}
							title={eventData.name}
							message={`Vote for contestant: ${content.firstName} ${content.lastName}`}
						/>
					)}

					<Button
						onClick={() => onVote(content)}
						disabled={voteBtnDisabled}
						style={{
							display: "inline",
							padding: "15px",
							marginTop: "5vh",
						}}
						className={
							voteBtnDisabled ? "btn_background_end" : "btn_background_active"
						}
						sx={{ width: { sm: "80%", xs: "100%" } }}
					>
						{voting ? (
							<PulseLoader
								size={7}
								margin={3}
								color={"#fff"}
								loading={voting}
							/>
						) : (
							"Vote"
						)}
					</Button>
					{/* <div className="contestant-qrCode-mobile">
            <img src={content.candidateQrCodeUrl} alt="contestant bar code" style={{ width: "100%" }} />
          </div> */}
					<Box
						className=""
						sx={{
							display: "flex",
							justifyContent: "end",
							padding: "20px",
							width: "auto",
						}}
					>
						<div>
							<p style={{ color: "grey" }}>Powered by:</p>
							<a href="https://noneho.com" target="_blank" rel="noreferrer">
								<img
									src={NonehoLogo}
									alt="Noneho logo"
									style={{ height: "24px", width: "120px" }}
								/>
							</a>
						</div>
					</Box>
				</div>
			</div>
		</div>
	);
};
