import {
  FETCH_EVENT,
  FETCH_EVENTS,
  FETCH_CONTESTANT,
  UPDATE_CANDIDATES,
  UPDATE_CANDIDATE,
  DOWNLOADED_TICKETS
} from "./types";

const initialState = {
  loading: false,
  data: {},
};

export default class Reducers {
  static getContestantReducer(state = initialState, action) {
    switch (action.type) {
      case `${FETCH_CONTESTANT}_PENDING`:
        return {
          ...state,
          loading: true,
        };
      case `${FETCH_CONTESTANT}_FULFILLED`:
        return {
          ...state,
          loading: false,
          data: action.payload,
        };
      case `${UPDATE_CANDIDATE}`:
        return {
          ...state,
          loading: false,
          data: {
            ...state.data,
            results: {
              ...state.data.results,
              ...action.payload,
            },
          },
        };
      case `${FETCH_CONTESTANT}_REJECTED`:
        return {
          ...state,
          loading: false,
          data: action.payload,
        };
      default:
        return state;
    }
  }

  static getEventsReducer(state = initialState, action) {
    switch (action.type) {
      case `${FETCH_EVENTS}_PENDING`:
        return {
          ...state,
          loading: true,
        };
      case `${FETCH_EVENTS}_FULFILLED`:
        return {
          ...state,
          loading: false,
          data: action.payload,
        };
      case `${FETCH_EVENTS}_REJECTED`:
        return {
          ...state,
          loading: false,
          data: action.payload,
        };
      default:
        return state;
    }
  }

  static getEventReducer(state = initialState, action) {
    switch (action.type) {
      case `${FETCH_EVENT}_PENDING`:
        return {
          ...state,
          loading: true,
        };
      case `${FETCH_EVENT}_FULFILLED`:
        return {
          ...state,
          loading: false,
          data: action.payload,
        };
      case `${UPDATE_CANDIDATES}`:
        return {
          ...state,
          loading: false,
          data: {
            ...state.data,
            results: {
              ...state.data.results,
              candidates: action.payload,
            },
          },
        };
      case `${FETCH_EVENT}_REJECTED`:
        return {
          ...state,
          loading: false,
          data: action.payload,
        };
      default:
        return state;
    }
  }
}
