import React, { useRef, useEffect } from "react";
import "../../../assets/styles/components/img_text.css";
import Zikomo from "../../../assets/images/ZikomoImage.png";
import HeaderBg from "../../../assets/images/headerBg.png";
import NonehoLogo from "../../../assets/images/NonehoBlueLogo.png";

export const svg = {};

export default ({ content, isBasicEvent, heroBg }) => {
	const svgRef = useRef(null);

	useEffect(() => {
		if (svgRef.current) {
			svgRef.current.setAttribute("fill", heroBg);
		}
	}, [heroBg]);
	const { eventData, content2, basicContent } = content;
	const svgString = `<svg id="visual" viewBox="0 0 1920 600" width="1920" height="600" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1"><rect x="0" y="0" width="1920" height="600" fill=${
		`"${heroBg}"` || "#044367"
	}></rect><path d="M0 537L35.5 536.3C71 535.7 142 534.3 213.2 535.2C284.3 536 355.7 539 426.8 539C498 539 569 536 640 534.8C711 533.7 782 534.3 853.2 537.2C924.3 540 995.7 545 1066.8 546.3C1138 547.7 1209 545.3 1280 542C1351 538.7 1422 534.3 1493.2 533.7C1564.3 533 1635.7 536 1706.8 538.2C1778 540.3 1849 541.7 1884.5 542.3L1920 543L1920 601L1884.5 601C1849 601 1778 601 1706.8 601C1635.7 601 1564.3 601 1493.2 601C1422 601 1351 601 1280 601C1209 601 1138 601 1066.8 601C995.7 601 924.3 601 853.2 601C782 601 711 601 640 601C569 601 498 601 426.8 601C355.7 601 284.3 601 213.2 601C142 601 71 601 35.5 601L0 601Z" fill="#f8f8f8" stroke-linecap="round" stroke-linejoin="miter"></path></svg>`;

	const dataUri = `data:image/svg+xml;charset=utf-8,${encodeURIComponent(
		svgString
	)}`;

	return eventData.id ? (
		<div
			style={{
				backgroundImage: `url(${HeaderBg})`,
				backgroundColor: heroBg,
				backgroundRepeat: "no-repeat",
				backgroundSize: "cover",
				backgroundPosition: "top left",
				animation: "wave 7s cubic-bezier( 0.36, 0.45, 0.63, 0.53) infinite",
				transform: "translate3d(0, 0, 0)",
			}}
		>
			<div ref={svgRef} className="row-space-between" id="introduction">
				<div className="intContent2">
					{isBasicEvent ? basicContent : content2}
				</div>
				<div className="intContent1">
					<div>
						<img
							src={eventData.coverPhoto || Zikomo}
							alt="most voted"
							id="introduction_img"
							// width={240}
						/>
					</div>
				</div>
			</div>
			{!isBasicEvent && (
				<div
					className=""
					style={{
						display: "flex",
						justifyContent: "flex-end",
						padding: "20px",
					}}
				>
					<div>
						<p style={{ color: "grey" }}>Powered by:</p>
						<a href="https://noneho.com" target="_blank" rel="noreferrer">
							<img
								src={NonehoLogo}
								alt="Noneho logo"
								style={{ height: "24px", width: "120px" }}
							/>
						</a>
					</div>
				</div>
			)}
		</div>
	) : null;
};
