import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import HomePage from "../views/pages/ContestantPage";
import EventsPage from "../views/pages/EventsPage";
import NominatePage from "../views/pages/NominationPage";

const AppRoute = () => (
  <BrowserRouter>
    <div className="app">
      <Switch>
        <Route exact path="/" component={EventsPage} />
        <Route exact path="/nominate" component={NominatePage} />
        <Route exact path="/:eventId/:contestantId" component={HomePage} />
      </Switch>
    </div>
  </BrowserRouter>
);

export default AppRoute;
