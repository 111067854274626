import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import EventsComponent from "../../components/events/EventsComponent";
import "../../../assets/styles/base.css";
import "../../../assets/styles/containers/events.css";
import { fullUrlAction } from "../../../redux/action";
import { FETCH_EVENTS } from "../../../redux/events/types";
import Backdrop from "../../../components/Backdrop";
import Loading from "../../../components/Loading";
import ImageIntroduction from "../../components/introductions/ImageIntroduction";

export default () => {
  const totalPages = 5;
  const [state, setState] = useState({
    currentPage: 1,
    loading: false,
    voted: {},
    openModel: false,
    displayCategory: "all",
  });

  const dispatch = useDispatch();
  const allEvents = useSelector((store) => store.events);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (!allEvents.data.results) {
      dispatch(
        fullUrlAction({
          method: "GET",
          path: `${process.env.EVENT_PUBLIC_API}/events`,
          actionType: FETCH_EVENTS,
        })
      );
    }
  }, []);

  return allEvents.loading && !allEvents.data.results ? (
    <Loading />
  ) : (
    <div>
      <ImageIntroduction />

      {allEvents.loading && allEvents.data.results ? (
        <Backdrop />
      ) : (
        <div>
          <EventsComponent
            votedCandidate={state.voted}
            voting={state.loading}
            eventsData={allEvents.data.results || []}
          />
        </div>
      )}
    </div>
  );
};
