import React, { Component } from "react";
import AppLayout from "../../layouts/AppLayout";
import EventContainer from "../containers/events/EventContainer";
import NominationContainer from "../containers/events/NominationContainer";

export class Event extends Component {
  render() {
    const { eventId } = this.props.match.params;

    return !eventId ? (
      <AppLayout>
        <NominationContainer eventId={eventId === undefined ? "ZikomoAfricaAwards20231692952330956" : eventId} />
      </AppLayout>
    ) : (
      <AppLayout>
        <NominationContainer />
      </AppLayout>
    );
  }
}

export default Event;