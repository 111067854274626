// eslint-disable-next-line import/no-named-as-default
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { groupBy } from "lodash";
import "../../../assets/styles/base.css";
import "../../../assets/styles/containers/contestants.css";
import { Button, Box } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import EventComponent from "../../components/events/EventComponent";
import { fullUrlAction, updateCandidates } from "../../../redux/action";
import { FETCH_EVENT } from "../../../redux/events/types";
import Loading from "../../../components/Loading";
import ImageTextComponent from "../../components/common/ImageTextComponent";
import apiService from "../../../services/apiService";
import Toast from "../../../components/MessageToast";
import BasicEventComponent from "../../components/events/BasicEventComponent";
import { formatDate, formatTime } from "../../../helpers/helperMethods";
import NoContent from "../../../components/NoContent";
import { firestore } from "../../../config/firebase";
import FixedAlert from "../../../components/FixedAlert";
import ReactShare from "../../../components/ReactShare";
import VotePaymentEntry from "../../components/votePaymentComponent/PaymentEntry";
import CountrySlider from "../../../components/OperatinCountries";
import ContestantBg from "../../../assets/images/cntestantBg.png";
import NonehoLogo from "../../../assets/images/NonehoLogo.png";
// import TicketPaymentEntry from "../../components/ticketPaymentComponent/PaymentEntry";

export default ({ eventId }) => {
	const history = useHistory();
	const [state, setState] = useState({
		currentPage: 1,
		loading: false,
		voted: {},
		paymentModel: false,
	});

	const handleOpen = () => history.push(`/${eventId}/buy-ticket`);

	const dispatch = useDispatch();
	const eventInfo = useSelector((store) => store.event);
	if (eventInfo.data.results && eventInfo.data.results.phase === "nomination") {
		history.push("/nominate");
	}
	const eventEnded =
		eventInfo.data.results &&
		(!eventInfo.data.results.isActive ||
			(moment().format("L") ===
			moment(eventInfo.data.results.endDate).format("L")
				? false
				: moment().isAfter(eventInfo.data.results.endDate)));
	const disableBuy = eventInfo.data.results && eventInfo.data.results.isActive;
	const handleCloseModal = () => {
		setState({ ...state, voted: {}, paymentModel: false });
	};

	useEffect(() => {
		window.scrollTo(0, 0);
		if (
			!eventInfo.data.results ||
			eventId !== (eventInfo.data.results && eventInfo.data.results.id)
		) {
			dispatch(
				fullUrlAction({
					method: "GET",
					path: `${process.env.EVENT_PUBLIC_API}/events/${eventId}`,
					actionType: FETCH_EVENT,
				})
			);
		}

		const candidatesQuery = firestore
			.collection(process.env.NONEHO_EVENTS_COLLECTION)
			.doc(eventId)
			.collection("candidates");
		const subscribeToDataChange = candidatesQuery.onSnapshot((snap) => {
			let cands = snap.docs.map((doc) => ({
				id: doc.id,
				candidateId: doc.id,
				...doc.data(),
			}));
			if (cands[0] && cands[0].category) {
				cands = groupBy(cands, "category");
				if (eventId === "KissSummerAwards1661777759866") {
					cands = [
						{
							categoryName: "Best Song",
							categoryCandidates: cands["Best Song"],
						},
						{
							categoryName: "Best Male Artist",
							categoryCandidates: cands["Best Male Artist"],
						},
						{
							categoryName: "Best New Artist",
							categoryCandidates: cands["Best New Artist"],
						},
						{
							categoryName: "Best Female Artist",
							categoryCandidates: cands["Best Female Artist"],
						},
						{
							categoryName: "Best Producer",
							categoryCandidates: cands["Best Producer"],
						},
						{
							categoryName: "Best Album",
							categoryCandidates: cands["Best Album"],
						},
					];
				} else {
					cands = Object.entries(cands).map((ent) => ({
						categoryName: ent[0],
						categoryCandidates: ent[1],
					}));
				}
			}
			dispatch(updateCandidates(cands));
		});
		return subscribeToDataChange;
	}, []);

	const onVote = async (candidate) => {
		if (
			!(
				eventInfo.data.results.payment &&
				eventInfo.data.results.payment.required
			)
		) {
			setState({ ...state, voted: candidate, loading: true });
		}
		return setState({ ...state, voted: candidate, paymentModel: true });
	};

	const onBuyTicket = () => {
		Toast({
			message: "Sorry, but we are currently not selling tickets 🙈",
			type: "success",
		});
	};
	const buyRequired =
		eventInfo.data.results &&
		eventInfo.data.results.payment &&
		eventInfo.data.results.payment.required;

	const content2 = {
		eventData: eventInfo.data.results || {},
		content2: (
			<>
				<p className="not-centered-title" style={{ color: "whitesmoke" }}>
					{eventInfo.data.results ? eventInfo.data.results.name : ""}
				</p>
				<p
					className="not-centered-subtitle"
					style={{
						fontSize: "15px",
						color: "rgb(136 146 150)",
						padding: 0,
					}}
				>
					{eventInfo.data.results
						? `${formatDate(eventInfo.data.results.startDate)} - ${formatDate(
								eventInfo.data.results.endDate
						  )}`
						: null}
				</p>
				<p
					className="not-centered-subtitle"
					style={{
						color: "white",
						fontSize: "18px",
						opacity: 0.8,
						padding: "10px 0px 10px 0px",
					}}
				>
					Venue: {eventInfo.data.results && eventInfo.data.results.venue}
				</p>
				{/* <p className="not-centered-subtitle">
          {eventInfo.data.results ? eventInfo.data.results.description : ""}
        </p> */}
				<p
					className="not-centered-subtitle"
					style={{
						color: "white",
						fontSize: "17px",
						opacity: 0.6,
						paddingBottom: 10,
					}}
				>
					Event By: {eventInfo.data.results && eventInfo.data.results.organizer}
				</p>
				{!eventEnded && (
					<ReactShare
						link={`https://vote.zikomoawards.com`}
						title={eventInfo.data.results && eventInfo.data.results.name}
						message={
							eventInfo.data.results && eventInfo.data.results.descriprion
						}
					/>
				)}
				{eventEnded && (
					<FixedAlert
						message={`This event ended  ${moment(
							eventInfo.data.results && eventInfo.data.results.endDate
						).fromNow()}`}
					/>
				)}
				<CountrySlider
					countries={eventInfo.data.results && eventInfo.data.results.countries}
				/>
				{/* <Box
					className=""
					sx={{
						position: "absolute",
						// float: "right",
						bottom: "0px",
						"@media (max-width: 768px)": {
							bottom: "300px",
							marginTop: "40px",
						},
						right: "20px",
					}}
				>
					<p style={{ color: "grey" }}>Powered by:</p>
					<a href="https://noneho.com" target="_blank" rel="noreferrer">
						<img
							src={NonehoLogo}
							alt="Noneho logo"
							style={{ width: "150px", height: "fit-content" }}
						/>
					</a>
				</Box> */}
			</>
		),
		basicContent: (
			<div>
				<p className="not-centered-title">
					{eventInfo.data.results ? eventInfo.data.results.name : ""}
				</p>
				<br />
				<p className="not-centered-subtitle">
					{eventInfo.data.results
						? `Date: ${formatDate(eventInfo.data.results.endDate)}`
						: null}
				</p>
				<p className="not-centered-subtitle">
					Venue: {eventInfo.data.results && eventInfo.data.results.venue}
				</p>
				<p className="not-centered-subtitle">
					Doors Opens: (
					{eventInfo.data.results &&
						`${formatTime(eventInfo.data.results.checkinTime)}`}
					)
				</p>
				<p className="not-centered-subtitle">
					{eventInfo.data.results && eventInfo.data.results.description}
				</p>
				<p className="not-centered-subtitle">
					Event By:{" "}
					{(eventInfo.data.results && eventInfo.data.results.organizer) ||
						(eventInfo.data.results && eventInfo.data.results.createdBy)}
				</p>
				{buyRequired ? (
					<Button
						onClick={handleOpen}
						disabled={!disableBuy}
						style={{
							padding: "15px",
							width: "100%",
							color: !disableBuy ? "#fff" : "#1876D1",
							backgroundColor: !disableBuy ? "#5D5C62" : "#fff",
							marginTop: "2.5vh",
						}}
					>
						Buy Ticket
					</Button>
				) : (
					""
				)}
				{/* <TicketModal handleClose={handleClose} open={open} eventInfo={eventInfo.data.results}/> */}
			</div>
		),
	};

	const isBasicEvent =
		eventInfo.data.results && eventInfo.data.results.category !== "Voting";

	return (eventInfo.loading && !eventInfo.data.results) ||
		(eventId !== (eventInfo.data.results && eventInfo.data.results.id) &&
			!eventInfo.data.error) ? (
		<Loading />
	) : eventInfo.data.error ? (
		<NoContent
			title="Event Not Found"
			path="/"
			pathName="events"
			customElements={
				<div>
					<h1> Please Check the URL </h1>
				</div>
			}
		/>
	) : (
		<div>
			<ImageTextComponent
				isBasicEvent={isBasicEvent}
				content={content2}
				heroBg="#00000"
			/>

			<div
				style={{
					// backgroundImage: `url(${ContestantBg})`,
					backgroundColor: "#f8f8f8",
					backgroundRepeat: "no-repeat",
					backgroundSize: "cover",
					backgroundPosition: "center",
					animation: "wave 7s cubic-bezier( 0.36, 0.45, 0.63, 0.53) infinite",
					transform: "translate3d(0, 0, 0)",
				}}
			>
				{eventInfo.data.results &&
					eventInfo.data.results.payment &&
					eventInfo.data.results.payment.required &&
					eventInfo.data.results.category === "Voting" && (
						<VotePaymentEntry
							open={state.paymentModel}
							title={`Support ${state.voted.firstName} ${state.voted.lastName}`}
							onCloseModel={handleCloseModal}
							description={`Noneho events payment | Support ${state.voted.firstName} on ${eventInfo.data.results.name}`}
							completeMessage={`Thank you for supporting ${state.voted.firstName} on ${eventInfo.data.results.name} with votes, Your votes get applied automatically after payment is completed`}
							itemId={state.voted.id || state.voted.candidateId}
							quantityLabel="votes"
							type="events"
							payload={{
								eventId,
								eventType: eventInfo.data.results.category,
								candidateId: state.voted.id || state.voted.candidateId,
								clientId: eventInfo.data.results.clientId,
							}}
							paymentNotRequired={eventInfo.data.results.payment.required}
							paymentOptions={{
								paymentOperators: eventInfo.data.results.payment.operators,
							}}
						/>
					)}
				{eventInfo.data.results &&
					eventInfo.data.results.payment &&
					!eventInfo.data.results.payment.required &&
					eventInfo.data.results.category === "Voting" && (
						<VotePaymentEntry
							open={state.paymentModel}
							title={`Support ${state.voted.firstName} ${state.voted.lastName}`}
							onCloseModel={handleCloseModal}
							description={`Noneho events payment | Support ${state.voted.firstName} on ${eventInfo.data.results.name}`}
							completeMessage={`Thank you for supporting ${state.voted.firstName} on ${eventInfo.data.results.name} with votes, Your votes get applied automatically after payment is completed`}
							itemId={state.voted.id || state.voted.candidateId}
							quantityLabel="votes"
							type="events"
							payload={{
								eventId,
								eventType: eventInfo.data.results.category,
								candidateId: state.voted.id || state.voted.candidateId,
								clientId: eventInfo.data.results.clientId,
							}}
							candidateId={state.voted.id || state.voted.candidateId}
							event={eventInfo.data.results}
							paymentNotRequired={eventInfo.data.results.payment.required}
							voting={state.loading}
						/>
					)}
				{isBasicEvent ? (
					<BasicEventComponent
						content={eventInfo.data.results}
						eventEnded={eventEnded}
					/>
				) : (
					<EventComponent
						onVote={onVote}
						votedCandidate={state.voted}
						voting={state.loading}
						eventInfo={{
							...eventInfo.data.results,
							candidates: eventInfo.data.results.categorized
								? eventInfo.data.results.candidates.map((cand) => ({
										...cand,
										categoryCandidates: cand.categoryCandidates.filter(
											(el) => !el.eliminated
										),
								  }))
								: eventInfo.data.results.candidates.filter(
										(item) => !item.eliminated
								  ),
						}}
						updatingVotes={eventInfo.loading && eventInfo.data.results}
						eventEnded={eventEnded}
						dedicated
					/>
				)}
			</div>
		</div>
	);
};
