import React from "react";
import Lodash from "lodash";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  root: {
    padding: "5%",
    "@media (max-width: 600px)": {
      padding: "10px 2%",
    },
  },
  inputElt: {
    padding: "10px 0",
    width: "100%",
    display: "grid",
  },
  supportedTitle: {
    width: "100%",
    textAlign: "center",
    borderBottom: "1px solid #888888",
    lineHeight: "0.1em !important",
    margin: "10px 0 20px",
  },
  supportedInline: {
    background: "#fff",
    padding: "0 10px",
  },
  avatar: {
    width: "70px !important",
    height: "70px !important",
    cursor: "pointer",
    objectFit: "cover",
    "@media (max-width:876px)": {
      width: "40px !important",
      height: "40px !important",
    },
    "@media (max-width:600px)": {
      width: "40px !important",
      height: "40px !important",
    },
  },
}));

export default ({ onChoosePaymentMethod, eventOpertors }) => {
  const filteredArray = Lodash.uniqBy(eventOpertors, item => item.name + item.icon);
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Typography variant="h5" align="center">
        Choose Payment Method
      </Typography>
      <Box className={classes.inputElt}>
        <Typography variant="body1" className={classes.supportedTitle}>
          <span className={classes.supportedInline}>
            Supported Payment Methods
          </span>
        </Typography>
      </Box>
      <Box className={classes.inputElt}>
        <Stack
          direction="row"
          spacing={1}
          alignItems="center"
          justifyContent="flex-start"
          flexWrap="wrap"
        >
          {filteredArray.map((operator, index) => <Avatar style={{ margin: "10px" }} key={index}
            alt={operator.name}
            src={operator.icon}
            variant="rounded"
            className={classes.avatar}
            onClick={() => onChoosePaymentMethod(operator, operator.method)}
          />)}
        </Stack>
      </Box>
    </Box>
  );
};
