import React from "react";
import { GoMail } from "react-icons/go";
import {
	BsFacebook,
	BsTwitter,
	BsLinkedin,
	BsInstagram,
	BsYoutube,
} from "react-icons/bs";
import FooterElement from "../../components/Footer/footerElement";
import Noneho from "../../assets/images/NonehoBlueLogo.png";
import "../../assets/styles/base.css";
import "../../assets/styles/components/footer.css";
import { openLinkInNewTab } from "../../helpers/helperMethods";

export default () => {
	const footerContent = {
		goals: [
			"Monetize Your Creative Work with Us",
			"Understand Your Media Performance",
			"Advertise Through Us",
		],
		platforms: [
			{ label: "INYARWANDA News", link: "https://inyarwanda.com/" },
			{ label: "INYARWANDA Music", link: "https://music.inyarwanda.com/" },
			{ label: "INYARWANDA Tv", link: "https://tv.inyarwanda.com/" },
		],
	};
	return (
		<div
			style={{
				// backgroundColor: window.location.pathname.includes("publishers")
				//   ? "#613A01"
				//   : "#092f53",
				backgroundColor: window.location.pathname.includes("publishers")
					? "#613A01"
					: "#000000",
			}}
			id="footerRoot"
		>
			<div className="row-space-around row-align-center">
				<div className="platforms" style={{ textAlign: "center" }}>
					{/* <p className="not-centered-subtitle">Platforms</p> */}
					{/* <div>
            {footerContent.platforms.map((platform) => (
              <FooterElement key={platform.label} content={platform} />
            ))}
          </div> */}
					<GoMail size={40} />
					{/* <h3>Email</h3> */}
					<p>admin@zikomoawards.com</p>
				</div>
				<div className="platforms">
					{/* <p className="not-centered-subtitle">Platforms</p>
          <div>
            {footerContent.platforms.map((platform) => (
              <FooterElement key={platform.label} content={platform} />
            ))}
          </div> */}
					<div>
						{/* <button style={{ padding: "10px 25px", textTransform: "uppercase", backgroundColor: "transparent", border: "1px solid white", borderRadius: "5px", color: "white", fontWeight: "600" }}>Cal Me</button> */}
					</div>
				</div>
				<div className="platforms icons">
					{/* <p className="not-centered-subtitle">Platforms</p>
          <div>
            {footerContent.platforms.map((platform) => (
              <FooterElement key={platform.label} content={platform} />
            ))}
          </div> */}
					<div style={{ display: "flex", justifyContent: "space-between" }}>
						<BsFacebook size={30} />
						<BsTwitter size={30} />
						<BsLinkedin size={30} />
						<BsInstagram size={30} />
						<BsYoutube size={30} />
					</div>
				</div>

				{/* <div>

        </div> */}
			</div>
			<div style={{ padding: "15px", textAlign: "right" }}>
				<p>Powered By:</p>
				<img
					src={Noneho}
					onClick={() => openLinkInNewTab("https://noneho.com")}
					alt="Noneho logo"
					style={{ height: "24px", width: "120px", cursor: "pointer" }}
				/>
			</div>
		</div>
	);
};
