import React from "react";
import "../../assets/styles/components/navbar.css";
import { useHistory } from "react-router";
import { IconButton } from "@mui/material";
import ChevronLeft from "@mui/icons-material/ChevronLeft";
import { openLinkInNewTab } from "../../helpers/helperMethods";

export default ({ Logo, logo, color }) => {
  const history = useHistory();

  const navigateTo = (url) => history.push(url);

  return (
    <div className="navRoot">
      <div className="navContent">
        {window.location.pathname !== "/" && (
          <IconButton onClick={() => history.goBack()}>
            <ChevronLeft className="back-btn" />
          </IconButton>
        )}
        <img src={logo} alt="Company logo" width="35px" height="35px" />
        <span className="navLogo" style={{ color: `${color}` }} onClick={() => navigateTo("/")}>
          {Logo || "noneho.events"}
        </span>
      </div>

      <div
        className="navButton"
        onClick={() => openLinkInNewTab("https://demand.noneho.com/events/new")} style={{ display: "none" }}
      >
        Create Event
      </div>
      <a href="https://www.zikomoawards.com/" target="_self"
        // className="navButton" 
        style={{ display: "flex", justifyContent: "space-between", maxWidth: "1000px", color: "white", textDecoration: "none" }}
      >
        <div>

          ABOUT US
        </div>
        {/* <div>

          OUR PRIVACY POLYCY
        </div> */}
      </a>
    </div>
  );
};
