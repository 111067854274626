import React from "react";
import "../../../assets/styles/components/introduction.css";
import wordSvg from "../../../assets/images/word.svg";

export default (props) => {
  const { isTop } = props;

  const content = {
    backgroundImage:
      "https://c1.staticflickr.com/5/4808/45715774034_09422e3fae_b.jpg",
  };

  return (
    <div className="imgIntroductionRoot">
      <div className="imgIntroduction">
        <img src={wordSvg} alt="word svg" />
      </div>
      <div
        style={{
          backgroundImage: `url(${content.backgroundImage})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundColor: "#1876D0",
          height: "65vh",
          color: "#fff",
          filter: "brightness(0.3)",
        }}
      ></div>
    </div>
  );
};
