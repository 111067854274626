import { applyMiddleware, combineReducers, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import promise from "redux-promise-middleware";
import thunk from "redux-thunk";

import { eventsReducers } from "./events";

const reducers = combineReducers({
  ...eventsReducers,
});

export default () => {
  const store = createStore(
    reducers,
    {},
    composeWithDevTools(applyMiddleware(thunk, promise))
  );

  return store;
};
