/* eslint-disable import/no-named-as-default */
import React, { useState } from "react";
import moment from "moment";
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
// import GoogleAd from "../GoogleAd";
import VerticalImageCard from "../../../components/Cards/verticalImageCard";
import Toast from "../../../components/MessageToast";
import SearchComponent from "../../../components/CandidatesSearch";

export default ({
  eventInfo,
  votedCandidate,
  updatingVotes,
  loading,
  eventEnded,
  onVote,
  ...rest
}) => {
  const [country, setCountry] = useState('');

  const handleChange = (event) => {
    setCountry(event.target.value);
  };
  const filteredContestants = eventInfo.categorized ? eventInfo.candidates.map(el => country ? { ...el, categoryCandidates: el.categoryCandidates.filter(contestant => contestant.countryCode === country) } : el) : country ? eventInfo.candidates.filter(contestant => contestant.countryCode === country) : eventInfo.candidates;
  return (
    <div className="contestants-root">
      <p className="title" style={{ color: "#000" }}> All Contestants</p>
      {/* change all contestants color */}
      <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", padding: "0px 10px" }}>
        <SearchComponent
          candidates={
            eventInfo.categorized && eventInfo.candidates.length > 0
              ? eventInfo.candidates
                .map((el) => el.categoryCandidates).reduce((prev, next) => prev.concat(next))
              : eventInfo.candidates
          }
          eventId={eventInfo.id}
        />
        <Box sx={{ minWidth: 170, maxWidth: 170 }}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Filter By Country</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={country}
              label="Filter By Country"
              onChange={handleChange}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {eventInfo.countries && eventInfo.countries.map((item, index) => <MenuItem key={index} value={item.countryCode}>{item.countryName}</MenuItem>)}
            </Select>
          </FormControl>
        </Box>
      </div>

      {eventInfo && eventInfo.categorized ? (
        filteredContestants.map((elt) => (elt.categoryCandidates.length > 0 &&
          <div key={elt.category} className="contestant-container">
            {elt.categoryCandidates.length > 0 && <p className="not-centered-title" style={{ color: "#000", display: elt.categoryCandidates.length > 0 ? "block" : "none" }}>{elt.categoryName}</p>}
            <div className="contestants">
              {elt.categoryCandidates
                .sort((a, b) => ((b && b.votes) || 0) - ((a && a.votes) || 0))
                .map((categoryCandidates) => (
                  <VerticalImageCard
                    eventEnded={eventEnded}
                    activeEvent={eventInfo.isActive}
                    key={categoryCandidates.candidateId}
                    isListElt
                    onVote={onVote}
                    event={eventInfo}
                    content={categoryCandidates}
                    votedCandidate={votedCandidate}
                    updatingVotes={updatingVotes}
                    {...rest}
                  />
                ))}

            </div>
          </div>
        ))
      ) : (
        <div className="contestant-container">
          <div className="contestants">
            {eventInfo &&
              filteredContestants
                .sort((a, b) => b.votes - a.votes)
                .map((categoryCandidates) => (
                  <VerticalImageCard
                    eventEnded={eventEnded}
                    onVote={onVote}
                    key={categoryCandidates.candidateId}
                    isListElt
                    event={eventInfo}
                    content={categoryCandidates}
                    votedCandidate={votedCandidate}
                    updatingVotes={updatingVotes}
                    {...rest}
                  />
                ))}
            {/* <GoogleAd googleAdId="ca-pub-7912746766834364" slot="3371492315" /> */}
          </div>
        </div>
      )}
    </div>
  )
};