import React from "react";
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  EmailIcon,
  FacebookIcon,
  LinkedinIcon,
  TwitterIcon,
  WhatsappIcon,
} from "react-share";

import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

export default ({ link, title, quote, message }) => (
  <Box py={1}>
    <Typography variant="subtitle1" py={1} sx={{ color: "white" }}>
      Share the link
    </Typography>
    <Grid container spacing={2}>
      <Grid item>
        <FacebookShareButton url={link} quote={quote || ""}>
          <FacebookIcon round size={45} />
        </FacebookShareButton>
      </Grid>
      <Grid item>
        <WhatsappShareButton url={link} title={title || ""}>
          <WhatsappIcon round size={45} />
        </WhatsappShareButton>
      </Grid>
      <Grid item>
        <TwitterShareButton url={link} title={title || ""}>
          <TwitterIcon round size={45} />
        </TwitterShareButton>
      </Grid>
      {/* <Grid item>
        <EmailShareButton url={link} subject={title || ""} body={message || ""}>
          <EmailIcon round size={45} />
        </EmailShareButton>
      </Grid>
      <Grid item>
        <LinkedinShareButton
          url={link}
          title={title || ""}
          summary={message || ""}
        >
          <LinkedinIcon round size={45} />
        </LinkedinShareButton>
      </Grid> */}
    </Grid>
  </Box>
);
