import apiService from "../services/apiService";
import fullUrlService from "../services/fullUrlService";
import { UPDATE_CANDIDATES, UPDATE_CANDIDATE } from "./events/types";

export const fullUrlAction =
  ({ method, path, data, options, actionType }) =>
  async (dispatch) =>
    await dispatch({
      type: actionType,
      payload: fullUrlService({ path, method, data, options, actionType }),
    });

export default ({ method, path, data, options, actionType }) =>
  async (dispatch) =>
    await dispatch({
      type: actionType,
      payload: apiService({ path, method, data, options, actionType }),
    });

export const updateCandidates = (candidates) => async (dispatch) =>
  await dispatch({
    type: UPDATE_CANDIDATES,
    payload: candidates,
  });

export const updateCandidate = (candidate) => async (dispatch) =>
  await dispatch({
    type: UPDATE_CANDIDATE,
    payload: candidate,
  });
