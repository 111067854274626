import * as React from "react";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { Button, CardActionArea, CardActions } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useHistory } from "react-router";
import PulseLoader from "react-spinners/PulseLoader";
import Skeleton from "@mui/material/Skeleton";
import {
	formatNumber,
	navigateToPage,
	useWindowDimensions,
} from "../../helpers/helperMethods";
import { grayColor } from "../../assets/styles/base";

const useStyles = makeStyles(() => ({
	contestantCard: {
		height: "fit-content",
		// padding: "10px",
		width: "100%",
		"@media(max-width:600px)": {
			height: "fit-content",
		},
	},
	root: {
		width: "100%",
		// boxShadow: "10px 5px 40px 20px #f3f3f3",
		boxShadow: "10px 5px 40px 20px #dfdfdf",
		borderRadius: 0,
		// border: "1px solid #795d1e",
		backgroundColor: "#795d1e !important",

		// "&:hover": {
		//   boxShadow: "10px 5px 40px 20px #dfdfdf",
		// },
	},

	cardActions: { padding: "20px 0" },
	smallDetails: { marginRight: "5px" },
}));

export default ({
	content,
	onVote,
	votedCandidate,
	event,
	voting,
	updatingVotes,
	eventEnded,
	activeEvent,
}) => {
	const { width } = useWindowDimensions();
	const classes = useStyles();
	const history = useHistory();
	const voteBtnDisabled =
		eventEnded ||
		!activeEvent ||
		(voting && votedCandidate.candidateId !== content.candidateId);
	const viewContestant = () =>
		navigateToPage(`/${event.id}/${content.candidateId}`, history);
	return (
		<Card className={classes.root}>
			<CardActionArea onClick={viewContestant}>
				<Box
					className={classes.contestantCard}
					style={{
						border: "none",
					}}
				>
					{content.imgUrl ? (
						<img
							src={content.imgUrl || content.photo}
							alt="contestant image"
							style={{ width: "100%", height: "100%", objectFit: "cover" }}
						/>
					) : (
						<img
							src={`https://via.placeholder.com/500x450.png?text=${
								content.name || content.firstName
							}`}
							alt="contestant image"
							style={{ width: "100%", height: "100%", objectFit: "cover" }}
						/>
					)}
				</Box>

				<CardContent
					className={classes.cartContent}
					style={{
						height: width > 600 ? "4vh" : "5vh",
						backgroundColor: "#795d1e",
					}}
				>
					<Typography
						variant="subtitle2"
						component="div"
						flexWrap
						style={{ lineHeight: 1.2, fontSize: 18, color: "#fff" }}
					>
						{(
							content.name || `${content.firstName} ${content.lastName}`
						).substring(0, 45)}
					</Typography>
					{/* <Typography
            variant="subtitle2"
            component="div"
            flexWrap
            style={{ lineHeight: 1.2, fontSize: 17, color: grayColor[0], }}
          >
            {(
              `from: ${content.countryName}`
            ).substring(0, 45)}
          </Typography> */}
					<Grid container justifyContent="space-between">
						<Typography
							variant="subtitle1"
							style={{
								color: grayColor[0],
								fontSize: 16,
								// color:"#956830"
							}}
						>
							{/* {updatingVotes &&
                votedCandidate.candidateId === content.candidateId ? (
                <Skeleton
                  variant="text"
                  style={{ height: "50px", width: "150px" }}
                />
              ) : (
                `${formatNumber(content.votes) || 0} Votes`
              )} */}
						</Typography>
						{content.contestantCode ? (
							<Typography variant="h6" sx={{ color: "#000" }}>
								<strong>{content.contestantCode}</strong>
							</Typography>
						) : null}
					</Grid>

					<br />
				</CardContent>
			</CardActionArea>
			<CardActions className={classes.cardActions}>
				<Button
					onClick={() => onVote(content)}
					disabled={voteBtnDisabled}
					style={{
						display: "inline",
						width: "70%",
						margin: " 0 20%",
						height: "50px",
					}}
					className={
						voteBtnDisabled ? "btn_background_end " : "btn_background_active"
					}
					// className={voteBtnDisabled ? "btn_background_end " : "btn_background_active"}
				>
					{voting && votedCandidate.candidateId === content.candidateId ? (
						<PulseLoader size={7} margin={3} color={"#fff"} loading={voting} />
					) : (
						"Vote"
					)}
				</Button>
			</CardActions>
		</Card>
	);
};
