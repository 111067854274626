import React from "react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { PulseLoader } from "react-spinners";

export default ({ onClick, disabled, loading, children, type }) => (
  <Box
    sx={{
      padding: "10px 0",
      width: "100%",
      textAlign: "center",
      opacity: "0.8",
      cursor: "pointer"
    }}
  >
    <Button
      variant="contained"
      onClick={onClick}
      type={type}
      disabled={disabled}
      sx={{
        padding: "15px 0",
        width: "50%",
        backgroundColor: "#000",
        display: "inline",
        "&:hover": {
          background: "#000",
          cursor: "pointer",
        },

        "@media (max-width: 600px)": {
          padding: "10px 0",
          width: "100%",
        },
      }}
    >
      {loading ? (
        <PulseLoader size={5} margin={3} color={"#fff"} loading={loading} />
      ) : (
        children
      )}
    </Button>
  </Box>
);
