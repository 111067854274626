import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import { makeStyles } from "@mui/styles";
import PulseLoader from "react-spinners/PulseLoader";
import SubmitButton from "../../../components/SubmitButton";
import "../../../assets/styles/components/buttons.css";
import Toast from "../../../components/MessageToast";
import fullUrlService from "../../../services/fullUrlService";

const useStyles = makeStyles(() => ({
	root: {
		padding: "30px 0",
		"@media (max-width: 600px)": {
			padding: "10px 2%",
		},
	},
	inputElt: {
		padding: "10px 0",
		width: "100%",
		display: "grid",
		left: "0px !important",
	},
	groupInputElt: {
		padding: "10px 0",
		display: "grid",
		gridGap: "10px",
		gridTemplateColumns: "repeat(2,1fr)",
		"@media (max-width: 600px)": {
			gridTemplateColumns: "repeat(1,1fr)",
			padding: "5px 0",
		},
	},
	selectTitle: {
		color: "grey",
	},
	submitBtn: {
		padding: "15px 0",
		width: "50%",
		backgroundColor: "#1876D1",
		textAlign: "center",

		"&:hover": {
			background: "#1876D1",
			cursor: "pointer",
		},
		"@media (max-width: 600px)": {
			padding: "10px 0",
			width: "100%",
		},
	},
	errorMsg: {
		color: "red",
	},
}));

export default ({
	showOTP,
	skipOTP,
	eventId,
	message = "",
	onOTPSubmit,
	loading = false,
	error,
	completed,
	candidateId,
	closeModal,
}) => {
	const classes = useStyles();
	const [state, setState] = useState({
		otp: null,
		phoneNumber: null,
		loading: false,
	});

	const onPhoneChange = (phone) => {
		setState({
			...state,
			phoneNumber: phone,
			formError: "",
		});
	};

	const onInputChange = (e) => {
		setState({
			...state,
			[e.target.name]: e.target.value,
			formError: "",
		});
	};

	const onPayment = async () => {
		if (!state.email || !state.phoneNumber) {
			return setState({
				...state,
				formError: "Please enter correct phone number and select amount",
			});
		}
		setState({
			...state,
			loading: true,
		});
		const response = await fullUrlService({
			method: "POST",
			path: `${process.env.EVENT_PUBLIC_API}/events/${eventId}/candidates/${candidateId}/vote`,
			data: {
				email: state.email,
				phoneNumber: state.phoneNumber,
				firstName: state.firstName,
				lastName: state.lastName,
			},
		});

		if (response.message) {
			setState({
				...state,
				loading: true,
			});
		}
		setTimeout(() => {
			if (!response.error && response.message) {
				closeModal();
				setState({
					...state,
					email: "",
					firstName: "",
					lastName: "",
					phone: "",
					loading: false,
				});
			}
			if (response.error && response.message) {
				setState({
					...state,
					email: "",
					firstName: "",
					lastName: "",
					phone: "",
					loading: false,
				});
			}
			Toast({
				message: response.message,
				type: response.error ? "error" : "success",
				duration: 5000,
			});
		}, 7000);
	};
	const onSubmitOtp = () => {
		onOTPSubmit(state.otp.replaceAll("\\s+", ""));
	};

	useEffect(() => {
		if (error || completed) {
			setState({
				otp: null,
				phoneNumber: null,
			});
		}
	}, [error, completed]);

	return (
		<Box>
			{showOTP && !skipOTP ? (
				<Box className={classes.inputElt}>
					<Typography variant="subtitle1">
						Please enter the MOMO validation OTP sent to you via SMS and
						Whatsapp to complete this transaction.
					</Typography>
					<br></br>
					<TextField
						id="otp"
						label="OTP"
						placeholder="Enter your OTP"
						name="otp"
						onChange={onInputChange}
						value={state.otp}
					/>
					<br></br>
					<Typography variant="subtitle1" color="#1876D1">
						{error && message}
					</Typography>
					<SubmitButton
						onClick={onSubmitOtp}
						loading={loading}
						disabled={loading}
					>
						Send OTP
					</SubmitButton>
				</Box>
			) : skipOTP ? (
				<Box
					style={{
						textAlign: "center",
						width: "100%",
					}}
				>
					<Typography variant="subtitle1">
						Complete payment on your phone to be proccessed, we will notify you
						once done.
					</Typography>
					<CircularProgress
						style={{
							display: "inline-block",
							padding: 30,
							color: "#1876D1",
						}}
					/>
				</Box>
			) : completed ? (
				<Box
					style={{
						textAlign: "center",
						width: "100%",
					}}
				>
					<Typography variant="subtitle1">
						Complete payment on your phone to be proccessed, we will notify you
						once done.
					</Typography>
					<CircularProgress
						style={{
							display: "inline-block",
							padding: 30,
							color: "#1876D1",
						}}
					/>
				</Box>
			) : (
				<React.Fragment>
					<Box className={classes.groupInputElt}>
						<TextField
							id="firstName"
							label="First Name"
							name="firstName"
							placeholder="Enter your First Name"
							onChange={onInputChange}
							value={state.firstName || ""}
						/>
						<TextField
							id="lastName"
							label="Last Name"
							name="lastName"
							placeholder="Enter your Last Name"
							onChange={onInputChange}
							value={state.lastName || ""}
						/>
					</Box>
					<Box className={classes.groupInputElt}>
						<TextField
							id="email"
							label="Email"
							name="email"
							required
							placeholder="Enter your email"
							onChange={onInputChange}
							value={state.email || ""}
						/>
						<PhoneInput
							country={"rw"}
							className={classes.inputElt}
							containerClass="phone_input"
							placeholder="Enter phone"
							enableLongNumbers={true}
							value={state.phone}
							onChange={onPhoneChange}
							inputStyle={{ width: "100%" }}
						/>
						<br></br>
						<Typography className={classes.errorMsg} variant="subtitle1">
							{state.formError || (error && message)}
						</Typography>
					</Box>
					<br></br>

					<SubmitButton
						onClick={onPayment}
						loading={state.loading}
						disabled={state.loading}
						className="btn_background_active"
					>
						{state.loading && (
							<PulseLoader size={7} margin={3} color={"#fff"} />
						)}
						Proceed
					</SubmitButton>
				</React.Fragment>
			)}
		</Box>
	);
};
