import React, { useEffect } from "react";
import { Provider } from "react-redux";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import AppRoute from "./routes/index";
import configureStore from "./redux/configureStore";

const store = configureStore();

const App = () => {
  useEffect(() => {
    window.process = {
      ...window.process,
    };
    // try {
    //   if (window.adsbygoogle) {
    //     // window.adsbygoogle.push({});
    //   } else {
    //     console.log("waiting until adsense is loaded");
    //   }
    //   window.adsbygoogle = window.adsbygoogle || [];
    // } catch (error) {
    //   console.log("error in adsencce", error);
    // }
  }, []);
  return (
    <Provider store={store}>
      <PayPalScriptProvider
        options={{
          "client-id": process.env.PAYPAL_CLIENT_ID,
          intent: "capture",
        }}
      >
        <AppRoute />
      </PayPalScriptProvider>
    </Provider>
  );
};

export default App;
