import React, { Component } from "react";
import AppLayout from "../../layouts/AppLayout";
import EventContainer from "../containers/events/EventContainer";
import EventsContainer from "../containers/events/EventsContainer";

export class Event extends Component {
  render() {
    const { eventId } = this.props.match.params;


    return !eventId ? (
      <AppLayout>
        <EventContainer eventId={eventId === undefined ? "ZikomoAfricaAwards20231692952330956" : eventId} />
      </AppLayout>
    ) : (
      <AppLayout>
        <EventsContainer />
      </AppLayout>
    );
  }
}

export default Event;
