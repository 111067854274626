import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Autocomplete from "@mui/material/Autocomplete";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useSelector, useDispatch } from "react-redux";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import PulseLoader from "react-spinners/PulseLoader";
import Button from "../../../components/SubmitButton";
import "../../../assets/styles/base.css";
import { fullUrlAction } from "../../../redux/action";
import fullUrlService from "../../../services/fullUrlService";
import CreatableSelect from "../../../components/creatableSelect";
import { FETCH_EVENT } from "../../../redux/events/types";
import Zikomo from "../../../assets/images/zikomo.jpeg";
import Toast from "../../../components/MessageToast";
import SubSaharanCountries from "../../../data/SubSaharanCountries.json";
import WorldCountries from "../../../data/WorldCountries.json";
import FixedAlert from "../../../components/FixedAlert";

const NominationContainer = ({ eventId }) => {
	const dispatch = useDispatch();
	const [state, setState] = useState({
		otp: null,
		nomineePhone: null,
		candidatePhone: null,
		loading: false,
	});
	const onNomineePhoneChange = (phone) => {
		setState({
			...state,
			nomineePhone: phone,
			formError: "",
		});
	};
	const onCandidatePhoneChange = (phone) => {
		setState({
			...state,
			candidatePhone: phone,
			formError: "",
		});
	};

	const eventInfo = useSelector((store) => store.event);

	useEffect(() => {
		window.scrollTo(0, 0);
		if (
			!eventInfo.data.results ||
			eventId !== (eventInfo.data.results && eventInfo.data.results.id)
		) {
			dispatch(
				fullUrlAction({
					method: "GET",
					path: `${process.env.EVENT_PUBLIC_API}/events/${eventId}`,
					actionType: FETCH_EVENT,
				})
			);
		}
	}, []);
	const [eventState, setEventState] = useState({
		eventInfo: {},
	});

	const [socialMedia, setSocialMedia] = useState([]);
	const handleSocialMediaChange = (change) => {
		setSocialMedia(change.map((el) => el.value));
	};

	const [nominatorCountry, setNominatorCountry] = useState(null);
	const [candidateCountry, setCandidateCountry] = useState(null);
	const [candidateCategory, setCandidateCategory] = useState(null);

	const handleCandidateCategory = (event, newValue) => {
		setCandidateCategory(newValue);
	};
	const handleCandidateCountry = (event, newValue) => {
		setCandidateCountry(newValue);
	};
	const handleNominatorCountry = (event, newValue) => {
		setNominatorCountry(newValue);
	};
	const formik = useFormik({
		initialValues: {
			nominee: {
				firstName: "",
				lastName: "",
				nickName: "",
				email: "",
			},
			candidate: {
				firstName: "",
				lastName: "",
				nickName: "",
				description: "",
				candidateEmail: "",
				website: {
					facebookLink: "",
					youtube: "",
					twitter: "",
				},
				candidatePhoneNumber: "",
			},
		},
		validationSchema: Yup.object({
			nominee: Yup.object({
				firstName: Yup.string().required("Required"),
				lastName: Yup.string().required("Required"),
				nickName: Yup.string().nullable(),
				email: Yup.string().email("Invalid email address").required("Required"),
			}),
			candidate: Yup.object({
				firstName: Yup.string().required("Required"),
				lastName: Yup.string().required("Required"),
				nickName: Yup.string().nullable(),
				description: Yup.string().nullable(),
				candidateEmail: Yup.string()
					.email("Invalid email address")
					.required("Required"),
			}),
		}),

		onSubmit: async (values, { resetForm }) => {
			// Handle form submission here
			if (!candidateCategory) {
				Toast({
					message: `Please select candidate category field`,
					type: "error",
					duration: 5000,
				});
				return;
			}
			if (!nominatorCountry) {
				Toast({
					message: `Please select Nominator Country and Phone field`,
					type: "error",
					duration: 5000,
				});
				return;
			}
			if (!candidateCountry) {
				Toast({
					message: `Please select Candidate Country and Phone field`,
					type: "error",
					duration: 5000,
				});
				return;
			}
			const formattedData = {
				nominee: {
					firstName: values.candidate.firstName,
					lastName: values.candidate.lastName,
					nickName: values.candidate.nickName,
					category: candidateCategory,
					email: values.candidate.candidateEmail,
					phoneNumber: state.candidatePhone,
					website: "",
					countryName: candidateCountry.label,
					countryCode: candidateCountry.value,
					socialMedia,
					description: values.candidate.description,
				},
				nominator: {
					firstName: values.nominee.firstName,
					lastName: values.nominee.lastName,
					nickName: values.nominee.nickName,
					email: values.nominee.email,
					phoneNumber: state.nomineePhone,
					countryName: nominatorCountry.label,
					countryCode: nominatorCountry.value,
				},
			};
			setState({
				...state,
				loading: true,
			});

			const response = await fullUrlService({
				method: "POST",
				path: `${process.env.EVENT_PUBLIC_API}/events/${eventId}/nominate`,
				data: formattedData,
			});
			Toast({
				message: response.message,
				type: response.error ? "error" : "success",
				duration: 5000,
				onClose: () => {
					if (!response.error) {
						resetForm();
						setCandidateCountry(null);
						setNominatorCountry(null);
						setCandidateCategory("");
						setEventState({
							eventInfo: {
								...eventState.eventInfo,
								categories: {},
							},
						});
						setState({
							...state,
							nomineePhone: "",
							candidatePhone: "",
							loading: false,
						});
					} else {
						setState({
							...state,
							loading: false,
						});
					}
				},
			});
		},
	});

	return (
		<div style={{ backgroundColor: "#eaeaea" }}>
			<Box
				sx={{
					backgroundColor: "#956830",
				}}
			>
				<Box sx={{ display: "flex", padding: "10px 20px" }}>
					<img src={Zikomo} alt="" width={100} height={100} />
					<Box sx={{ padding: "0px 20px", lineHeight: 1.2 }}>
						<h2>{eventInfo.data.results && eventInfo.data.results.name}</h2>
						<p style={{ fontWeight: "600", lineHeight: "2rem" }}>
							{eventInfo.data.results && eventInfo.data.results.venue}
						</p>
						<p style={{ opacity: 0.6 }}>Contact Us</p>
						{/* <p style={{ lineHeight: "2rem", opacity: .6 }}>Phone: +250788244702</p> */}
						<p style={{ opacity: 0.8 }}>
							Email: <a href="#">admin@zikomoawards.com</a>
						</p>
					</Box>
				</Box>
			</Box>
			<Box
				sx={{
					display: "flex",
					justifyContent: "center",
					padding: "15vh 20px 40vh",
				}}
			>
				<FixedAlert
					title="Closed"
					message="Zikomo nomination is currenctly closed, please wait for the next phase to be communicated!"
					severity="info"
				/>
			</Box>

			{/* <form
				onSubmit={formik.handleSubmit}
				className="nomination-forn-container"
			>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<h2>Nominator Information</h2>
					</Grid>
					<Grid item xs={12} md={6}>
						<TextField
							name="firstName"
							label="First Name"
							fullWidth
							error={
								formik.touched.nominee?.firstName &&
								formik.errors.nominee?.firstName
							}
							helperText={
								formik.touched.nominee?.firstName &&
								formik.errors.nominee?.firstName
							}
							{...formik.getFieldProps("nominee.firstName")}
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<TextField
							name="lastName"
							label="Last Name"
							fullWidth
							error={
								formik.touched.nominee?.lastName &&
								formik.errors.nominee?.lastName
							}
							helperText={
								formik.touched.nominee?.lastName &&
								formik.errors.nominee?.lastName
							}
							{...formik.getFieldProps("nominee.lastName")}
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<TextField
							name="nickName"
							label="Nick Name"
							error={
								formik.touched.nominee?.nickName &&
								formik.errors.nominee?.nickName
							}
							helperText={
								formik.touched.nominee?.nickName &&
								formik.errors.nominee?.nickName
							}
							{...formik.getFieldProps("nominee.nickName")}
							fullWidth
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<FormControl fullWidth>
							<Autocomplete
								id="country-select-demo"
								sx={{ width: "100%" }}
								options={WorldCountries}
								autoHighlight
								getOptionLabel={(option) => option.label}
								renderOption={(props, option) => (
									<Box
										component="li"
										sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
										{...props}
									>
										<img
											loading="lazy"
											width="20"
											src={`https://flagcdn.com/w20/${option.value.toLowerCase()}.png`}
											srcSet={`https://flagcdn.com/w40/${option.value.toLowerCase()}.png 2x`}
											alt=""
										/>
										{option.label}
									</Box>
								)}
								renderInput={(params) => (
									<TextField
										{...params}
										label="Choose a country"
										inputProps={{
											...params.inputProps,
											autoComplete: "new-password", // disable autocomplete and autofill
										}}
									/>
								)}
								value={nominatorCountry}
								onChange={handleNominatorCountry}
							/>
						</FormControl>
					</Grid>
					<Grid item xs={12} md={6}>
						<TextField
							name="email"
							label="Email"
							fullWidth
							error={
								formik.touched.nominee?.email && formik.errors.nominee?.email
							}
							helperText={
								formik.touched.nominee?.email && formik.errors.nominee?.email
							}
							{...formik.getFieldProps("nominee.email")}
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<PhoneInput
							country={
								(nominatorCountry && nominatorCountry.value.toLowerCase()) ||
								"rw"
							}
							className={{
								padding: "10px 0",
								width: "100%",
								display: "grid",
								left: "0px !important",
							}}
							containerClass="phone_input"
							placeholder="Enter phone"
							enableLongNumbers={true}
							value={state.phone}
							onChange={onNomineePhoneChange}
							inputStyle={{ width: "100%" }}
						/>
					</Grid>
					<Grid item xs={12}>
						<h2>Nominated Candidate Information</h2>
					</Grid>
					<Grid item xs={12} md={6}>
						<TextField
							name="firstName"
							label="First Name"
							fullWidth
							error={
								formik.touched.candidate?.firstName &&
								formik.errors.candidate?.firstName
							}
							helperText={
								formik.touched.candidate?.firstName &&
								formik.errors.candidate?.firstName
							}
							{...formik.getFieldProps("candidate.firstName")}
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<TextField
							name="lastName"
							label="Last Name"
							fullWidth
							error={
								formik.touched.candidate?.lastName &&
								formik.errors.candidate?.lastName
							}
							helperText={
								formik.touched.candidate?.lastName &&
								formik.errors.candidate?.lastName
							}
							{...formik.getFieldProps("candidate.lastName")}
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<TextField
							name="nickName"
							label="Nick Name"
							fullWidth
							error={
								formik.touched.candidate?.nickName &&
								formik.errors.candidate?.nickName
							}
							helperText={
								formik.touched.candidate?.nickName &&
								formik.errors.candidate?.nickName
							}
							{...formik.getFieldProps("candidate.nickName")}
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<TextField
							name="candidateEmail"
							label="Candidate's Email"
							fullWidth
							error={
								formik.touched.candidate?.candidateEmail &&
								formik.errors.candidate?.candidateEmail
							}
							helperText={
								formik.touched.candidate?.candidateEmail &&
								formik.errors.candidate?.candidateEmail
							}
							{...formik.getFieldProps("candidate.candidateEmail")}
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<FormControl fullWidth>
							<Autocomplete
								id="country-select-demo"
								sx={{ width: "100%" }}
								options={SubSaharanCountries}
								autoHighlight
								getOptionLabel={(option) => option.label}
								renderOption={(props, option) => (
									<Box
										component="li"
										sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
										{...props}
									>
										<img
											loading="lazy"
											width="20"
											src={`https://flagcdn.com/w20/${option.value.toLowerCase()}.png`}
											srcSet={`https://flagcdn.com/w40/${option.value.toLowerCase()}.png 2x`}
											alt=""
										/>
										{option.label}
									</Box>
								)}
								renderInput={(params) => (
									<TextField
										{...params}
										label="Choose a country"
										inputProps={{
											...params.inputProps,
											autoComplete: "new-password", // disable autocomplete and autofill
										}}
									/>
								)}
								value={candidateCountry}
								onChange={handleCandidateCountry}
							/>
						</FormControl>
					</Grid>
					<Grid item xs={12} md={6}>
						<PhoneInput
							country={
								(candidateCountry && candidateCountry.value.toLowerCase()) ||
								"rw"
							}
							className={{
								padding: "10px 0",
								width: "100%",
								display: "grid",
								left: "0px !important",
							}}
							containerClass="phone_input"
							placeholder="Enter phone"
							enableLongNumbers={true}
							value={state.phone}
							onChange={onCandidatePhoneChange}
							inputStyle={{ width: "100%" }}
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<FormControl fullWidth>
							<Autocomplete
								id="country-select-demo"
								sx={{ width: "100%" }}
								options={
									(eventInfo.data.results &&
										eventInfo.data.results.categories) ||
									[]
								}
								autoHighlight
								getOptionLabel={(option) => option}
								renderOption={(props, option) => (
									<Box
										component="li"
										sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
										{...props}
									>
										{option}
									</Box>
								)}
								renderInput={(params) => (
									<TextField
										{...params}
										label="Choose a Category"
										inputProps={{
											...params.inputProps,
											autoComplete: "new-password", // disable autocomplete and autofill
										}}
									/>
								)}
								value={candidateCategory}
								onChange={handleCandidateCategory}
							/>
						</FormControl>
					</Grid>
					<Grid item xs={12} md={6}>
						<CreatableSelect
							placeholder="Add social media links"
							defaultValues={[]}
							options={[]}
							onChange={handleSocialMediaChange}
						/>
					</Grid>
					<Grid item xs={12}>
						<TextField
							name="description"
							label="Reason for Nomination"
							fullWidth
							multiline
							rows={4}
							error={
								formik.touched.candidate?.description &&
								formik.errors.candidate?.description
							}
							helperText={
								formik.touched.candidate?.description &&
								formik.errors.candidate?.description
							}
							{...formik.getFieldProps("candidate.description")}
						/>
					</Grid>

					<Grid item xs={12}>
						<Button type="submit" disabled={state.loading}>
							{state.loading ? (
								<PulseLoader
									size={7}
									margin={3}
									color={"#fff"}
									loading={state.loading}
								/>
							) : (
								"Submit"
							)}
						</Button>
					</Grid>
				</Grid>
			</form> */}
		</div>
	);
};
export default NominationContainer;
