import React from "react";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";

export default ({ severity = "info", title, message }) => (
  <Alert severity={severity} sx={{ width: { sm: "400px", xs: "auto" } }}>
    {title && <AlertTitle>{title}</AlertTitle>}
    {message}
  </Alert>
);
