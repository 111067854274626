import React, { useState, useEffect } from "react";
import { TextField, Typography, Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import PhoneInput from "react-phone-input-2";
import Select from "@mui/material/Select";
import CircularProgress from "@mui/material/CircularProgress";
import SubmitButton from "../../../components/SubmitButton";
import "../../../assets/styles/components/buttons.css";

const useStyles = makeStyles((theme) => ({
  root: {
    "@media (max-width: 600px)": {
      padding: "10px 2%",
    },
  },
  inputElt: {
    padding: "10px 0",
    width: "100%",
    display: "grid",
  },
  groupInputElt: {
    padding: "10px 0",
    display: "grid",
    gridGap: "10px",
    gridTemplateColumns: "repeat(2,1fr)",

    "@media (max-width: 600px)": {
      gridTemplateColumns: "repeat(1,1fr)",
      padding: "5px 0",
    },
  },
  selectTitle: {
    color: "grey",
  },
  errorMsg: {
    color: "red",
  },
}));

export default ({
  paymentOptions = {},
  message = "",
  onPaymentSubmit,
  paymentProvider,
  paymentPath,
  operatorCurrencies,
  loading = false,
  error,
  completed,
}) => {
  const classes = useStyles();
  const [selectedCurrency, setSelectedCurrency] = useState({});

  const [state, setState] = useState({
    currency: selectedCurrency.currency && selectedCurrency.currency.code || operatorCurrencies[0],
    // options: paymentOptions.options[operatorCurrencies[0]],
  });
  const onAmountChoosing = (e) => {
    const { value: amount } = e.target;
    const votes = selectedCurrency.currency.voteOptions.find(vote => vote.amount === amount);
    setState({
      ...state,
      amount,
      votes: votes.votes,
      formError: "",
    });
  };

  const onCurrencySelection = (e) => {
    const { value: currency } = e.target;
    setSelectedCurrency({
      ...selectedCurrency,
      currency,
      method: operatorCurrencies
    })
  }



  const onPhoneChange = (phone) => {
    setState({
      ...state,
      phoneNumber: phone,
      formError: "",
    });
  };

  const onInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "currency") {
      setState({
        ...state,
        [name]: value,
        options: paymentOptions.options[value],
        formError: "",
      });
    } else {
      setState({
        ...state,
        [name]: value,
        formError: "",
      });
    }
  };

  const onPayment = () => {
    if (
      !state.amount ||
      !state.email ||
      !state.email.includes("@") ||
      !state.email.includes(".") ||
      !state.currency
    ) {
      return setState({
        ...state,
        formError: "Please enter correct email and select amount",
      });
    }
    return onPaymentSubmit({
      email: state.email.replaceAll(/\s/g, ""),
      phoneNumber: state.phoneNumber,
      amount: state.amount,
      votes: state.votes,
      currency: selectedCurrency.currency.code,
      operator: paymentProvider,
      paymentPath: paymentProvider.providerPath,
      providerName: paymentProvider.providerName,
      firstName: state.firstName,
      lastName: state.lastName
    });
  };

  useEffect(() => {
    if (error || completed) {
      setState({
        currency: state.currency,
        // options: paymentOptions.options[paymentOptions.currency[0]],
      });
    }
  }, [error, completed]);

  return (
    <Box className={classes.root}>
      {completed && !error ? (
        <Box
          style={{
            textAlign: "center",
            width: "100%",
          }}
        >
          <Typography variant="subtitle1">
            Your payment is being processed, we will notify you once done.
          </Typography>
          <CircularProgress
            style={{
              display: "inline-block",
              padding: 30,
              color: "#1876D1",
            }}
          />
        </Box>
      ) : (
        <>
          <Box className={classes.groupInputElt}>
            <TextField
              id="firstName"
              label="First Name"
              name="firstName"
              placeholder="Enter your First Name"
              onChange={onInputChange}
              value={state.firstName || ""}
            />
            <TextField
              id="lastName"
              label="Last Name"
              name="lastName"
              placeholder="Enter your Last Name"
              onChange={onInputChange}
              value={state.lastName || ""}
            />
          </Box>
          <Box className={classes.groupInputElt}>
            <TextField
              id="email"
              label="Email"
              name="email"
              placeholder="Enter your email"
              onChange={onInputChange}
              value={state.email || ""}
            />
            <FormControl>
              <InputLabel shrink>Phone</InputLabel>
              <PhoneInput
                country={"rw"}
                containerClass="phone_input"
                className={classes.inputElt}
                enableLongNumbers={true}
                value={state.phone}
                onChange={onPhoneChange}
                inputStyle={{ width: "100%" }}
              />
            </FormControl>
          </Box>
          {/* <Box className={classes.inputElt}>
            
          </Box> */}

          <Box className={classes.groupInputElt}>
            <FormControl fullWidth>
              <InputLabel id="amount-label">Select Currency</InputLabel>
              <Select
                id="currency"
                value={selectedCurrency.currency}
                defaultSelected="RWF"
                name="currency"
                onChange={onCurrencySelection}
              >
                {operatorCurrencies.map((elt) => (
                  <MenuItem key={elt} value={elt}>
                    {elt.code}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>


            <FormControl fullWidth>
              <InputLabel id="amount-label">Option</InputLabel>
              <Select
                labelId="amount-label"
                disabled={!selectedCurrency.currency}
                id="amount"
                value={state.amount || ""}
                label="Amount"
                name="amount"
                onChange={onAmountChoosing}
              >
                {selectedCurrency.currency && selectedCurrency.currency.voteOptions.map((currency) => (
                  <MenuItem key={currency.amount} value={currency.amount}>
                    {currency.amount} {selectedCurrency.currency.code} - {currency.votes} vote(s)
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>

          <Typography className={classes.errorMsg} variant="subtitle1">
            {state.formError || (error && message)}
          </Typography>

          <SubmitButton
            onClick={onPayment}
            loading={loading}
            disabled={loading}
            className="btn_background_active"

          >
            Proceed With Payment
          </SubmitButton>
        </>
      )}
    </Box>
  );
};
