/* eslint-disable import/no-unresolved */
import React from 'react';
import Slider from 'react-slick';
import Flag from 'react-country-flag';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


const CountrySlider = ({ countries }) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    arrows: false,
    responsive: [
      {
        breakpoint: 400,
        settings: { slidesToShow: 2, slidesToScroll: 1, infinite: true, dots: false }
      },
      {
        breakpoint: 768,
        settings: { slidesToShow: 3, slidesToScroll: 2, infinite: true }
      },
      {
        breakpoint: 1024,
        settings: { slidesToShow: 3, slidesToScroll: 3, infinite: true }
      }
    ]
  };

  return (
    <div style={{ maxWidth: "550px" }}>
      <Slider {...settings}>
        {countries && countries.map(country => (
          <div key={country.countryCode} style={{ textAlign: "center" }}>
            <Flag
              style={{
                width: '4em',
                height: '4em',
              }}
              countryCode={country.countryCode}
              svg />
            <p>{country.countryName.includes('Democratic Republic') ? "DRC" : country.countryName.includes("Tanzania") ? "Tanzania" : country.countryName}</p>
          </div>

        ))}
      </Slider>
    </div>

  );
};


export default CountrySlider;