import React, { Component } from "react";
import AppLayout from "../../layouts/AppLayout";
import ContestantContainer from "../containers/contestant/ContestantContainer";

export class Contestant extends Component {
  render() {
    const {contestantId} = this.props.match.params;
    const {eventId} = this.props.match.params

    return (
      <AppLayout>
        <ContestantContainer contestantId={contestantId} eventId={eventId}/>
      </AppLayout>
    );
  }
}

export default Contestant;
