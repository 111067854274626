import * as React from "react";

export const TabSelector = ({ isActive, children, onClick }) => {
  const defaultStyles = {
    cursor: "pointer",
    border: "none",
    borderBottom: "none",
    padding: "10px",
    color: "gray",
    backgroundColor: "#F8F8F8",
  };
  const activeStyles = {
    ...defaultStyles,
    color: "#1876D0",
    borderBottom: "2px solid #1876D0",
  };
  return (
    <button
      onClick={onClick}
      style={isActive ? activeStyles : defaultStyles}
    >
      {children}
    </button>
  );
};
