import React from "react";
import { Markup } from "interweave";
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon,
  TelegramShareButton,
  TelegramIcon,
} from "react-share";

export default ({ content, eventEnded }) => (
  <div className="contestant">
    <br />
    <div style={{ margin: 15 }}>
      <Markup content={content.description} />
    </div>

    <div style={{ margin: 15 }}>
      <p className="not-centered-subtitle">Share Event with your friends</p>
      <FacebookShareButton
        url={
          "https://nonehoevents.web.app/CHORALEDEKIGALI1639383526747" ||
          `${window.location.href}`
        }
        quote={`${content.name}`}
        style={{ padding: "5px" }}
      >
        <FacebookIcon size={32} round />
      </FacebookShareButton>
      <TwitterShareButton
        url={
          "https://nonehoevents.web.app/CHORALEDEKIGALI1639383526747" ||
          `${window.location.href}`
        }
        quote={`${content.name}`}
        style={{ padding: "5px" }}
      >
        <TwitterIcon size={32} round />
      </TwitterShareButton>
      <WhatsappShareButton
        url={
          "https://nonehoevents.web.app/CHORALEDEKIGALI1639383526747" ||
          `${window.location.href}`
        }
        quote={`${content.name}`}
        style={{ padding: "5px" }}
        separator=":: "
      >
        <WhatsappIcon size={32} round />
      </WhatsappShareButton>

      <TelegramShareButton
        url={
          "https://nonehoevents.web.app/CHORALEDEKIGALI1639383526747" ||
          `${window.location.href}`
        }
        quote={`${content.name}`}
        style={{ padding: "5px" }}
      >
        <TelegramIcon size={32} round />
      </TelegramShareButton>
    </div>
    

  </div>
);
