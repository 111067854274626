// eslint-disable-next-line import/no-named-as-default
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import ContestantComponent from "../../components/contestants/ContestantComponent";
import Toast from "../../../components/MessageToast";
import "../../../assets/styles/base.css";
import "../../../assets/styles/containers/contestants.css";
import { FETCH_EVENT, FETCH_CONTESTANT } from "../../../redux/events/types";
import { fullUrlAction, updateCandidate } from "../../../redux/action";
import Loading from "../../../components/Loading";
import apiService from "../../../services/apiService";
import ImageTextComponent from "../../components/common/ImageTextComponent";
import { formatDate } from "../../../helpers/helperMethods";
import { firestore } from "../../../config/firebase";
import FixedAlert from "../../../components/FixedAlert";
import PaymentEntry from "../../components/votePaymentComponent/PaymentEntry";
import NoContent from "../../../components/NoContent";

export default ({ contestantId, eventId }) => {
	const [state, setState] = useState({
		currentPage: 1,
		loading: false,
		voted: {},
		paymentModel: false,
	});

	const dispatch = useDispatch();

	const singleContestant = useSelector((store) => store.contestant);
	const eventInfo = useSelector((store) => store.event);

	const contestant = singleContestant.data.results;
	const event = eventInfo.data.results || {};

	const eventEnded =
		!event.isActive ||
		(moment().format("L") === moment(event.endDate).format("L")
			? false
			: moment().isAfter(event.endDate));

	const handleCloseModal = () => {
		setState({ ...state, paymentModel: false });
	};

	useEffect(() => {
		window.scrollTo(0, 0);
		// if (!event.id) {
		dispatch(
			fullUrlAction({
				method: "GET",
				path: `${process.env.EVENT_PUBLIC_API}/events/${eventId}`,
				actionType: FETCH_EVENT,
			})
		);
		// }

		dispatch(
			fullUrlAction({
				method: "GET",
				path: `${process.env.EVENT_PUBLIC_API}/events/${eventId}/candidates/${contestantId}`,
				actionType: FETCH_CONTESTANT,
			})
		);

		const candidateQuery = firestore
			.collection(process.env.NONEHO_EVENTS_COLLECTION)
			.doc(eventId)
			.collection("candidates")
			.doc(contestantId);
		const subscribeToDataChange = candidateQuery.onSnapshot((snap) => {
			if (!snap.data().eliminated) {
				dispatch(
					updateCandidate({ id: snap.id, candidateId: snap.id, ...snap.data() })
				);
			}
		});
		return subscribeToDataChange;
	}, []);

	const onVote = async () => {
		console.log("Vote Clicked");
		if (!event.payment.required) {
			// setState({ ...state, loading: true });
			// const response = await apiService({
			//   method: "PATCH",
			//   path: `/votings/${eventId}/candidates/${contestant.candidateId}`,
			// });
			// Toast({
			//   message: response.message,
			//   type: response.error ? "error" : "success",
			// });
			// if (!response.error) {
			//   singleContestant.data.results.votes = response.results.votes;
			// }
			// return setState({ ...state, loading: response && false });
		}
		return setState({ ...state, voted: contestant, paymentModel: true });
	};

	const content2 = {
		eventData: event,
		basicContent: (
			<div>
				<p className="not-centered-title">{event.name || ""}</p>
				<br />
				<p className="not-centered-subtitle">
					{`${formatDate(event.startDate)} - ${formatDate(event.endDate)}`}
				</p>
				<p className="not-centered-subtitle">
					Venue:
					{event.venue || "unset"}
				</p>
				<p className="not-centered-subtitle">{event.description || ""}</p>
				<p className="not-centered-subtitle">
					Event By: {event.organizer || event.createdBy}
				</p>
				{eventEnded && (
					<FixedAlert
						message={`This event ended  ${moment(event.endDate).fromNow()}`}
					/>
				)}
			</div>
		),
	};

	const methods = event.payment || {};
	return (singleContestant.loading && !singleContestant.data.results) ||
		(singleContestant.loading &&
			singleContestant.data.results &&
			singleContestant.data.results.candidateId !== contestantId) ? (
		<Loading />
	) : (
		<div>
			{event.payment &&
				event.payment.operators &&
				eventInfo.data.results.payment.required && (
					<PaymentEntry
						open={state.paymentModel}
						title={`Support for ${state.voted.firstName} ${state.voted.lastName}`}
						onCloseModel={handleCloseModal}
						description={`Noneho events payment | Support ${state.voted.firstName} on ${event.name}`}
						completeMessage={`Thank you for supporting ${state.voted.firstName} on ${event.name} with votes, Your votes get applied automatically after payment is completed`}
						itemId={state.voted.id || state.voted.candidateId}
						quantityLabel="votes"
						type="events"
						payload={{
							eventId,
							eventType: event.category,
							candidateId: contestantId,
							clientId: event.clientId,
						}}
						candidateId={contestantId}
						paymentNotRequired={eventInfo.data.results.payment.required}
						paymentOptions={{
							paymentOperators: event.payment.operators,
						}}
					/>
				)}

			{event.payment && !eventInfo.data.results.payment.required && (
				<PaymentEntry
					open={state.paymentModel}
					title={`Support for ${state.voted.firstName} ${state.voted.lastName}`}
					onCloseModel={handleCloseModal}
					description={`Noneho events payment | Support ${state.voted.firstName} on ${event.name}`}
					completeMessage={`Thank you for supporting ${state.voted.firstName} on ${event.name} with votes, Your votes get applied automatically after payment is completed`}
					itemId={state.voted.id || state.voted.candidateId}
					quantityLabel="votes"
					type="events"
					payload={{
						eventId,
						eventType: event.category,
						candidateId: contestantId,
						clientId: event.clientId,
					}}
					candidateId={contestantId}
					paymentNotRequired={eventInfo.data.results.payment.required}
					paymentOptions={{
						paymentOperators: event.payment.operators,
					}}
				/>
			)}

			{singleContestant.data.results ? (
				<ContestantComponent
					content={singleContestant.data.results || {}}
					onVote={onVote}
					votedCandidate={state.voted}
					voting={state.loading}
					btnColor={{ bg: "rgb(7, 41, 77)", color: "rgb(255, 198, 0)" }}
					eventData={event}
					eventEnded={eventEnded}
				/>
			) : (
				<NoContent
					title={`Contestant Not Found`}
					path={`/${eventId}`}
					pathName="Contestants"
				/>
			)}
			<ImageTextComponent
				isBasicEvent={true}
				content={content2}
				heroBg="#00000"
			/>
		</div>
	);
};
