/* eslint-disable import/no-named-as-default */
import React, { useState, useEffect } from "react";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Link from "@mui/material/Link";
import { styled } from "@mui/material";
import { ModalUnstyled } from "@mui/base";
import Box from "@mui/material/Box";
import CloseIcon from "@mui/icons-material/Close";
import { useFlutterwave, closePaymentModal } from "flutterwave-react-v3";
import FreeVoting from "./NoPaymentComponent";
import fullUrlService from "../../../services/fullUrlService";
import PaymentMethods from "./PaymentMethods";
import MobilePayment from "./MobilePayment";
import CardPayment from "./CardPayment";
import PaypalPayment from "./PaypalPayment";
import { firestore } from "../../../config/firebase";
import { openLinkInNewTab } from "../../../util/newTab";
import Toast from "../../../components/MessageToast";
import NonehoLogo from "../../../assets/images/NonehoBlueLogo.png";

const StyledModal = styled(ModalUnstyled)`
	position: fixed;
	z-index: 1300;
	right: 0;
	bottom: 0;
	top: 0;
	left: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	outline: none;
	padding: 0 5px;
`;

const Backdrop = styled("div")`
	z-index: -1;
	position: fixed;
	right: 0;
	bottom: 0;
	top: 0;
	left: 0;
	background-color: rgba(0, 0, 0, 0.5);
	-webkit-tap-highlight-color: transparent;
`;

const dialogContentStyle = {
	// ask francois about this
	width: "50%",
	padding: "5vh 5%",
	height: "fit-content",
	backgroundColor: "#fff",
	borderRadius: "6px",
	outline: "none",
	overflowY: "auto",
	position: "relative",
	maxHeight: "89vh",
	"@media (max-width: 600px)": {
		width: "98%",
		height: "fit-content",
	},
};

export default ({
	title = "Confirm Payment",
	description,
	paymentOptions,
	open = false,
	onCloseModel,
	type,
	itemId,
	payload,
	quantityLabel,
	paymentNotRequired,
	candidateId,
	completeMessage = "Your payment was successful, votes will be applied soon",
}) => {
	const [state, setState] = useState({
		countries: [],
		operatorIcon: null,
		operatorName: null,
		method: null, // mobile, card (flutterwave), paypal (card or account)
	});

	const onChoosePaymentMethod = (operator, selectedMethod) => {
		setState({
			...state,
			method: operator.method,
			countries: operator.countries,
			currencies: operator.currencies,
			operatorIcon: operator.icon,
			operatorName: operator.name,
			selectedMethod,
			provider: {
				providerPath: operator.providerPath,
				providerName: operator.providerName,
			},
		});
	};
	const handleFlutterPayment = useFlutterwave({
		public_key: process.env.FLUTTER_PUBLIC_KEY,
		tx_ref: state.tx_ref,
		amount: state.amount,
		currency: state.currency,
		payment_options: "card",
		customer: {
			email: state.email,
			phonenumber: "",
			name: title,
		},
		customizations: {
			title,
			description,
			logo: "https://demand.noneho.com/assets/img/logo.png",
		},
	});

	const afterInitTransaction = (response, provider, operator, providerName) => {
		switch (state.method) {
			case "MobileMoney":
				setState((prev) => ({
					...prev,
					showOTP:
						provider.providerName === "Flutterwave" &&
						!response.error &&
						response.results &&
						response.results.charge.redirect &&
						true,

					skipOTP:
						provider.providerName === "Flutterwave" &&
						!response.error &&
						response.results &&
						!response.results.charge.redirect &&
						true,
					redirect:
						provider.providerName === "Flutterwave" &&
						response.results &&
						response.results.charge.redirect,
					completed: provider.providerName === "Intouch Rwanda",
				}));
				break;
			case "card":
				setState((prev) => ({
					...prev,
					initFlutterwave:
						!response.error && providerName === "Flutterwave" && true,
					initDPOPay: !response.error && providerName === "DPO",
					completed: providerName === "Flutterwave" && true,
				}));
				if (providerName === "DPO" && !response.error) {
					// redirect to DPO payment page
					window.location.assign(
						`https://secure.3gdirectpay.com/payv3.php?ID=${response.results.charge.transToken}`
					);
				}
				break;
			case "paypal":
				setState((prev) => ({
					...prev,
					showPaypal: !response.error && providerName,
					completed: false,
				}));
				break;
			default:
				break;
		}
	};

	const initializePayment = async ({
		email,
		firstName,
		lastName,
		phoneNumber,
		amount,
		votes,
		currency,
		country,
		operator,
		paymentPath,
		providerName,
	}) => {
		setState({
			...state,
			loading: true,
			error: false,
			completed: false,
			skipOTP: false,
			showPaypal: false,
			showOTP: false,
		});

		const data = {
			amount,
			email,
			votes,
			phone_number: phoneNumber,
			currency,
			type,
			id: itemId,
			countryName: state.method === "MobileMoney" && country.name,
			countryCode: state.method === "MobileMoney" && country.code,
			operator: state.operatorName || operator,
			method: state.method,
			payment_options: state.method,
			customer: {
				email,
				firstName,
				lastName,
				phoneNumber,
			},
			payload: {
				...payload,
				[quantityLabel]: votes,
			},
			description,
			// originalUrl: `https://${window.location.hostname
			//   }/payment/complete?returnUrl=${window.location.pathname
			//   }&message=${completeMessage.replace("votes" + quantity + "votes")}`,
			originalUrl: `https://noneho.com/payment/complete?returnUrl=${
				window.location.pathname
			}&message=${completeMessage.replace("votes" + votes + "votes")}`,
		};
		const dataCards = {
			amount,
			votes,
			email,
			phone_number: phoneNumber,
			currency,
			payment_options: state.method,
			type,
			id: itemId,
			operator: state.operatorName || operator,
			method: state.method,
			customer: {
				email,
				firstName,
				lastName,
				phoneNumber,
			},
			payload: {
				...payload,
				[quantityLabel]: votes,
			},
			description,
			// originalUrl: `https://${window.location.hostname
			//   }/payment/complete?returnUrl=${window.location.pathname
			//   }&message=${completeMessage.replace("votes" + quantity + "votes")}`,
			originalUrl: `https://noneho.com/payment/complete?returnUrl=${
				window.location.pathname
			}&message=${completeMessage.replace("votes" + votes + "votes")}`,
		};
		// quantity

		let transAPIPath = process.env.PAYMENT_API;
		if (state.selectedMethod === "MobileMoney") {
			transAPIPath += country.providerPath;
		} else transAPIPath += paymentPath;

		const response = await fullUrlService({
			method: "POST",
			path: transAPIPath,
			data: state.method === "MobileMoney" ? data : dataCards,
		});
		setState({
			...state,
			message: response.message,
			loading: false,
			error: response.error,
			tx_ref: response.results && response.results.transaction.tx_ref,
			amount,
			currency,
			email,
		});
		if (state.error || response.error) {
			return Toast({
				message:
					state.message ||
					response.message ||
					"there has been an error please contact an admistrator...",
				type: "error",
				duration: 4000,
			});
		}
		afterInitTransaction(response, country, operator, providerName);
	};

	const onOTPSubmit = async (otp) => {
		const data = {
			otp,
			redirect: state.redirect,
		};
		setState({ ...state, loading: true });
		const response = await fullUrlService({
			method: "POST",
			path: `${process.env.PAYMENT_API}/flutterwave/${state.tx_ref}/otp`,
			data,
		});

		setState({
			...state,
			message: response.message,
			loading: false,
			error: response.error,
			completed: !response.error,
			showOTP: response.error,
		});
		if (response.error || response.error) {
			return Toast({
				message:
					state.error ||
					response.message ||
					"there has been an error please contact an admistrator...",
				type: "error",
				duration: 4000,
			});
		}
	};

	const onApprovePaypalOrder = () => {
		setState({
			...state,
			message: "Your payment is being processed, we will notify you once done",
			loading: false,
			error: false,
			completed: true,
			showPaypal: false,
		});
	};

	const onCancelPaypalOrder = () => {
		setState({
			...state,
			message: "Payment was cancelled, please try again",
			loading: false,
			error: true,
			completed: true,
			showPaypal: false,
		});
	};

	const onErrorPaypalOrder = () => {
		setState({
			...state,
			message: "An error occured processing payment, please try again",
			loading: false,
			error: true,
			completed: true,
			showPaypal: false,
		});
	};

	const onClosingModal = () => {
		setState({
			method: null,
		});
		onCloseModel();
	};

	useEffect(() => {
		let payQueryListener;
		if ((state.completed || state.skipOTP) && !state.error) {
			const payQuery = firestore
				.collection(process.env.COMPILED_TRANSACTIONS_COLLECTION)
				.doc(state.tx_ref);
			payQueryListener = payQuery.onSnapshot((snap) => {
				if (snap.exists) {
					const { status } = snap.data();
					if (status === "successful") {
						setState({
							method: null,
						});
						onClosingModal();
						Toast({
							message: completeMessage,
							type: "success",
							duration: 4000,
						});
					}
					if (status.includes("fail")) {
						setState({
							method: null,
						});
						onClosingModal();
						Toast({
							message:
								"Your payment was not successfuly processed, try again or contact support",
							type: "error",
							duration: 4000,
						});
					}
				}
			});
		}
		return payQueryListener;
	}, [state.completed, state.skipOTP]);
	if (state.initFlutterwave) {
		handleFlutterPayment({
			callback: (res) => {
				closePaymentModal();
				setState({
					...state,
					completed: true,
					skipOTP: true,
					message: "Payment didn't complete, please try again!",
					loading: false,
					error: res.status === "failed",
					initFlutterwave: false,
				});
			},
			onClose: () => {
				closePaymentModal();
				setState({
					...state,
					completed: false,
					message: "Payment Modal closed",
					loading: false,
					initFlutterwave: false,
					error: false,
					skipOTP: true,
				});
			},
		});
	}

	return (
		<StyledModal
			aria-labelledby="Noneho Payment Dialog"
			aria-describedby="Perform payment for Noneho services online"
			open={open}
			onClose={onClosingModal}
			BackdropComponent={Backdrop}
		>
			<Box sx={dialogContentStyle}>
				<IconButton
					aria-label="Close"
					onClick={onClosingModal}
					style={{
						float: "right",
						margin: "-3vh -3%",
					}}
				>
					<CloseIcon />
				</IconButton>
				<Typography variant="h6">{title}</Typography>
				<br></br>
				{!state.method && !paymentNotRequired && (
					<FreeVoting
						candidateId={candidateId}
						eventId={payload.eventId}
						onPaymentSubmit={initializePayment}
						onOTPSubmit={onOTPSubmit}
						message={state.message}
						showOTP={state.showOTP}
						skipOTP={state.skipOTP}
						// paymentOptions={paymentOptions.mobileOptions}
						loading={state.loading}
						closeModal={onClosingModal}
						error={state.error}
						completed={state.completed}
					/>
				)}
				{state.method === "MobileMoney" && paymentNotRequired ? (
					<MobilePayment
						operatorCountries={state.countries}
						paymentProvider={state.providerId}
						onPaymentSubmit={initializePayment}
						onOTPSubmit={onOTPSubmit}
						message={state.message}
						showOTP={state.showOTP}
						skipOTP={state.skipOTP}
						paymentOptions={paymentOptions.mobileOptions}
						loading={state.loading}
						error={state.error}
						completed={state.completed}
					/>
				) : state.method === "card" && paymentNotRequired ? (
					<CardPayment
						paymentProvider={state.provider}
						operatorCurrencies={state.currencies}
						onPaymentSubmit={initializePayment}
						message={state.message}
						loading={state.loading}
						error={state.error}
						completed={state.completed}
						paymentOptions={paymentOptions.cardOptions}
					/>
				) : state.method === "paypal" && paymentNotRequired ? (
					<PaypalPayment
						onPaymentSubmit={initializePayment}
						message={state.message}
						loading={state.loading}
						error={state.error}
						completed={state.completed}
						paymentOptions={paymentOptions.cardOptions}
						description={description}
						tx_ref={state.tx_ref}
						showPaypal={state.showPaypal}
						onApprovePaypalOrder={onApprovePaypalOrder}
						onCancelPaypalOrder={onCancelPaypalOrder}
						onErrorPaypalOrder={onErrorPaypalOrder}
					/>
				) : (
					paymentNotRequired && (
						<PaymentMethods
							onChoosePaymentMethod={onChoosePaymentMethod}
							eventOpertors={
								paymentOptions.paymentOperators &&
								paymentOptions.paymentOperators
							}
						/>
					)
				)}
				<Paper
					elevation={0}
					sx={{
						display: "flex",
						justifyContent: "space-between",
						alignItems: "center",
						marginTop: "20px",
						// textAlign: "end",
						// position: "absolute",
						// right: "0px",
						// bottom: "15px",
						// paddingRight: "30px",
					}}
				>
					<Box className="" sx={{}}>
						<p style={{ color: "grey" }}>Powered by:</p>
						<a href="https://noneho.com" target="_blank" rel="noreferrer">
							<img
								src={NonehoLogo}
								alt="Noneho logo"
								style={{ height: "24px", width: "120px" }}
							/>
						</a>
					</Box>
					<Link
						href="https://noneho.com/terms-conditions"
						target="_blank"
						sx={{ color: "gray" }}
					>
						Terms & conditions
					</Link>
				</Paper>
			</Box>
		</StyledModal>
	);
};
