/* eslint-disable import/no-unresolved */
/* eslint-disable max-classes-per-file */
import React, { Component } from "react";
import { withRouter } from "react-router";
import moment from "moment";
import Alert from "@mui/material/Alert";
import ShareIcon from "@mui/icons-material/Share";
import "../../assets/styles/components/card.css";
import { formatDate } from "../../helpers/helperMethods";
import FixedAlert from "../FixedAlert";

class CardHeader extends React.Component {
  render() {
    const { image } = this.props;
    const style = {
      backgroundImage: `url("${image}")`,
    };
    return (
      <header style={style} id={image} className="card-header">
      </header>
    );
  }
}

class Button extends Component {
  render() {
    const { title, content } = this.props;
    return (
      content.category === "Voting" && content.paymentRequired ? <button className="button button-primary card-button">
        <i className="fa fa-chevron-right"></i>
        {title || "Vote"}
      </button> : content.category === "Concert" && content.paymentRequired ? <button className="button button-primary card-button">
        <i className="fa fa-chevron-right"></i>
        {title || "Buy Ticket"}
      </button> : <button className="button button-primary card-button">
        <i className="fa fa-chevron-right"></i>
        {title || "Find out more"}
      </button>
    );
  }
}

class CardBody extends Component {
  render() {
    const { content, router } = this.props;


    const eventEnded =
      content &&
      ((moment().format("L") ===
        moment(content.endDate).format("L")
        ? false
        : moment().isAfter(content.endDate)));

    return (
      <div
        className="card-body"
        onClick={() => this.props.router.push(`/${content.id}`)}
      >
        <h2 className="card-title">{content.name}</h2>
        <br />

        <p className="body-content">
          <br />
          {content.category !== "Concert" && <p className="date">{`${formatDate(content.startDate)} - ${formatDate(
            content.endDate
          )}`}</p>}
          {content.category === "Concert" && <p className="date">{`Date: ${formatDate(
            content.endDate
          )}`}</p>}

          <span>Event By: {content.organization}</span>
        </p>
        <div>

          {eventEnded && <Alert severity="info" sx={{ width: { sm: "fit-content", xs: "auto" }, fontSize: "13px" }}>
            {`This event ended  ${moment(
              content && content.endDate
            ).fromNow()}`}
          </Alert>}
          {!eventEnded && <div style={{ width: { sm: "fit-content", xs: "auto" }, fontSize: "13px", height: "45px" }}>

          </div>}
        </div>

        <Button content={content} router={router} />
      </div>
    );
  }
}

class Card extends Component {
  render() {
    const { content } = this.props;

    return (
      <article className="card">
        <CardHeader image={content.imgUrl} />
        <CardBody content={content} router={this.props.history} />
      </article>
    );
  }
}

export default withRouter(Card);
